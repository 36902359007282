import { ErrorHandler, Injectable } from "@angular/core";
import { PsaLogger } from "./psa-logger.service";
import * as Rollbar from "rollbar";
import { ConfigService } from "../config.service";

@Injectable()
export class RollbarService implements ErrorHandler, PsaLogger {
  private rollbar: Rollbar;

  constructor(config: ConfigService) {
    this.rollbar = new Rollbar({
      accessToken: config.rollbarAccessToken,
      reportLevel: "error",
      captureUncaught: true,
      captureUnhandledRejections: true,
      enabled: config.isProduction,
      payload: {
        environment: config.environmentName,
        client: {
          javascript: {
            source_map_enabled: true,
            code_version: config.codeVersion,
            guess_uncaught_frames: true
          }
        }
      }
    });
  }

  public handleError(err: any): void {
    this.rollbar.error(err.originalError || err);
  }

  public debug(message: string) {
    this.rollbar.debug(message);
  }

  public info(message: string) {
    this.rollbar.info(message);
  }

  public warning(message: string) {
    this.rollbar.warning(message);
  }

  public error(message: string, e?: Error) {
    this.rollbar.error(message, e);
  }

  public critical(message: string) {
    this.rollbar.critical(message);
  }

  public setAuthenticatedUser(username: string) {
    this.rollbar.configure({
      payload: {
        person: {
          id: username
        }
      }
    });
  }

  public clearAuthenticatedUser() {
    this.rollbar.configure({
      payload: {
        person: null
      }
    });
  }
}
