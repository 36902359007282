import { Injectable, ErrorHandler } from "@angular/core";
import { PsaLogger } from "./psa-logger.service";
import { RollbarService } from "./rollbar.service";
//import { PsaAppInsightsService } from "./psaAppInsights.service";

@Injectable()
export class MultiLoggerService implements ErrorHandler, PsaLogger {
  private loggers: Array<ErrorHandler & PsaLogger>;

  //constructor(rollbar: RollbarService, ai: PsaAppInsightsService) {
  //  this.loggers = [rollbar, ai];
  //}
  constructor(rollbar: RollbarService) {
    this.loggers = [rollbar];
  }

  public debug(message: string) {
    for (const logger of this.loggers) {
      logger.debug(message);
    }
  }

  public info(message: string) {
    for (const logger of this.loggers) {
      logger.info(message);
    }
  }

  public warning(message: string) {
    for (const logger of this.loggers) {
      logger.warning(message);
    }
  }

  public error(message: string, e?: Error) {
    for (const logger of this.loggers) {
      logger.error(message);
    }
  }

  public critical(message: string) {
    for (const logger of this.loggers) {
      logger.critical(message);
    }
  }

  public setAuthenticatedUser(username: string) {
    for (const logger of this.loggers) {
      logger.setAuthenticatedUser(username);
    }
  }

  public clearAuthenticatedUser() {
    for (const logger of this.loggers) {
      logger.clearAuthenticatedUser();
    }
  }

  public handleError(error: any): void {
    for (const logger of this.loggers) {
      logger.handleError(error);
    }
  }
}
