import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler, isDevMode } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppListComponent } from './components/app-list/app-list.component';
import { UnauthenticatedComponent } from './components/unauthenticated/unauthenticated.component';
//import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { VersionListComponent } from './components/version-list/version-list.component';
import { VersionDownloadComponent } from './components/version-download/version-download.component';
import { PlatformChooserComponent } from './components/platform-chooser/platform-chooser.component';
import { UiModule } from './ui/ui.module';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { BusyComponent } from './components/busy/busy.component';
//import { SigninReturnComponent } from './components/signin-return/signin-return.component';
import { SilentRenewComponent } from './components/silent-renew/silent-renew.component';
//import { ConfigClientService } from './config-client/config-client.service';
//import { AuthService } from './services/auth.service';
import { NotificationService } from './services/notification.service';
//import { ImplicitConfigClient } from './config-client/_gen/common.config';
import { ContentClient } from './services/_gen/api';
import { ConfigService } from "./services/config.service";
//import { CONFIG_API_CLIENT_TOKEN } from './config-client/common-config.model';
import { PsaLogger, PsaConsoleLogger } from "./services/errorHandler/psa-logger.service";
import { RollbarService } from "./services/errorHandler/rollbar.service";
import { MultiLoggerService } from "./services/errorHandler/multi-logger.service";
import { MultiLoggerDevService } from "./services/errorHandler/multi-logger-dev.service";
import { environment } from '../environments/environment';
import { MessageBoxComponent } from './components/error-modal/message-box.component';
import { ModalModule } from 'ngx-bootstrap/modal';

import { MsalService, MSAL_INSTANCE, MsalGuard, MsalInterceptor, MsalBroadcastService } from './msal';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG } from './msal/constants';
import { MsalGuardConfiguration } from './msal/msal.guard.config';
import { MsalInterceptorConfig } from './msal/msal.interceptor.config';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: environment.authority,
      redirectUri: environment.redirectUri
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfig {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.protectedResource, [environment.protectedResourceScope]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    AppListComponent,
    UnauthenticatedComponent,
    //UnauthorizedComponent,
    VersionListComponent,
    VersionDownloadComponent,
    PlatformChooserComponent,
    BackButtonComponent,
    BusyComponent,
    //SigninReturnComponent,
    SilentRenewComponent,
    MessageBoxComponent
  ],
  entryComponents: [MessageBoxComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    UiModule,
    ModalModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useExisting: NotificationService, multi: true },
    //ConfigClientService,
    ConfigService,
    ContentClient,
    NotificationService,
    /* AuthService,
    {
      provide: ImplicitConfigClient,
      useFactory: (http: HttpClient) => {
        return new ImplicitConfigClient(http, environment.configServiceUrl);
      },
      deps: [HttpClient]
    }, */
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useValue: {
        interactionType: InteractionType.Redirect
      } as MsalGuardConfiguration
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    //{ provide: CONFIG_API_CLIENT_TOKEN, useExisting: ContentClient, multi: true },
    RollbarService,
    environment.production ? MultiLoggerService : MultiLoggerDevService,
    PsaConsoleLogger,
    {
      provide: ErrorHandler,
      useExisting: environment.production ? MultiLoggerService : MultiLoggerDevService
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
