import { Injectable, Injector, ErrorHandler } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { MessageBoxComponent } from '../../components/error-modal/message-box.component';

export interface PsaLogger {
  debug(message: string);
  info(message: string);
  warning(message: string);
  error(message: string, e?: Error);
  critical(message: string);
  setAuthenticatedUser(username: string);
  clearAuthenticatedUser();
}

@Injectable()
export class PsaConsoleLogger implements PsaLogger, ErrorHandler {

  modalService: BsModalService;

  constructor(private injector: Injector) { }

  public handleError(error: any): void {
    if (error && error.status >= 400 && error.status < 500) {
      // Don't throw auth errors...
      return;
    }

    this.error("Unhandled Exception!", error.originalError || error);
  }

  public debug(message: string) {
    console.log(message);
  }

  public info(message: string) {
    console.log(message);
  }

  public warning(message: string) {
    console.log(message);
  }

  public error(message: string, e?: Error) {
    console.log(message, e);

    // Throw up a general-purpose error modal
    if (this.modalService == null) {
      this.modalService = this.injector.get(BsModalService);
    }
    this.modalService.show(MessageBoxComponent);
  }

  public critical(message: string) {
    console.log(message);
  }

  public setAuthenticatedUser(username: string) {
    console.log("Current User is: " + username);
  }

  public clearAuthenticatedUser() {
    console.log("User logged out!");
  }
}
