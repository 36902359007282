import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { GIT_VERSION_INFO } from 'src/environments/gitVersionInfo';

@Injectable()
export class ConfigService {

  constructor() { }

  public get isProduction(): boolean {
    return environment.production;
  }

  public get rollbarAccessToken(): string {
    return environment.rollbarAccessToken;
  }

  public get environmentName(): string {
    return environment.envName;
  }

  public get codeVersion(): string {
    return GIT_VERSION_INFO.revision;
  }
}
