import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit, NgZone } from '@angular/core';
import { NotificationService } from '../../services/notification.service';

@Component({
  styleUrls: ['./busy.component.css'],
  selector: 'psa-app-busy',
  animations: [
    trigger('visibilityChanged', [
      state('shown', style({ opacity: 1, display: 'flex' })),
      state('hidden', style({ opacity: 0, display: 'none' })),
      transition('* => *', animate('.2s'))
    ])
  ],
  template: `
  <div [@visibilityChanged]="busyValue ? 'shown' : 'hidden'" class="busy backdrop">
    <i class="spinner fa fa-refresh"></i>
  </div>`
})
export class BusyComponent implements OnInit {
  public busyValue = false;

  constructor(private notificationService: NotificationService) {
  }

  public ngOnInit() {
    this.notificationService.subscribeBusy(isBusy => {
      this.busyValue = isBusy;
    });
  }
}
