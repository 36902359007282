import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { finalize } from 'rxjs/operators';

@Injectable()
export class NotificationService implements HttpInterceptor {
  private busyLevel = 0;
  private busyStream = new Subject<boolean>();
  private alertStream = new Subject<AlertConfig>();

  public incrementBusy(isMoBizzay: boolean) {
    this.busyLevel += isMoBizzay ? 1 : -1;
    this.busyStream.next(this.busyLevel > 0);
  }

  public subscribeBusy(callback: (value: boolean) => void) {
    return this.busyStream.subscribe(callback);
  }

  public showAlert(cfg: AlertConfig) {
    this.alertStream.next(cfg);
  }

  public subscribeAlert(callback: (value: AlertConfig) => void) {
    return this.alertStream.subscribe(callback);
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.incrementBusy(true);

    return next.handle(req).pipe(finalize(() => {
      this.incrementBusy(false);
    }));
  }
}

export interface AlertConfig {
  type: 'success' | 'info' | 'warning' | 'danger';
  msg: string;
}
