import { Component } from '@angular/core';
import { MsalService } from '../../msal/msal.service';
//import { ConfigClientService } from 'src/app/config-client/config-client.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  constructor(private authService: MsalService) { }
  
}
